import Component from '../component/component';

import './share.scss';

interface IShareIconType {
    type?: string;
    url?: string;
    twitterPopUp?: IPopUp;
    linkedInPopUp?: IPopUp;
    facebookPopUp: IPopUp;
}

interface IPopUp {
    href?: string;
    width?: string;
    height?: string;
    resize?: string;
}

export default class Share extends Component {

    static initSelector: string = '.js-share';

    element: JQuery;
    settings: IShareIconType;

    constructor(target: HTMLElement) {
        super(target);
        this.settings = {
            facebookPopUp: {
                height: '520',
                href: 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href,
                resize: 'yes',
                width: '570',
            },
            linkedInPopUp: {
                height: '520',
                href: 'https://www.linkedin.com/shareArticle?url=' + window.location.href + '&mini=true',
                resize: 'yes',
                width: '570',
            },
            twitterPopUp: {
                height: '500',
                href: 'https://twitter.com/share?url=' + window.location.href + '&amp;text=' + $(document).attr('title') + '&amp',
                resize: 'yes',
                width: '400',
            },
            type: this.element.data('social'),
            url: window.location.href,
        };

        this.init();
    }

    static openPopUp(href: string, intWidth: string, intHeight: string, strResize: string): void {
        const strTitle: string = encodeURI($(document).attr('title'));
        const strParam: string = 'width=' + intWidth + ',height=' + intHeight + ',resizable=' + strResize;

        window.open(href, strTitle, strParam).focus();
    }

    init(): void {
        switch (this.settings.type) {
            case 'facebook':
                this.element.on('click', this.share.bind(this, this.settings.facebookPopUp));
                break;
            case 'twitter':
                this.element.on('click', this.share.bind(this, this.settings.twitterPopUp));
                break;
            case 'linkedin':
                this.element.on('click', this.share.bind(this, this.settings.linkedInPopUp));
                break;
            default:
                console.error('Unknown share component');
                break;
        }
    }

    share(settings: IPopUp, event: JQuery.Event): void {
        event.preventDefault();
        Share.openPopUp(
            settings.href,
            settings.width,
            settings.height,
            settings.resize,
        );
    }
}

