import { Fancybox } from '@fancyapps/ui';

import Icon from '../icon/icon';
import Spinner from '../spinner/spinner';

import './lightbox.scss';

// For more details about available options and plugins: https://fancyapps.com/docs/ui/fancybox

Fancybox.bind('[data-fancybox]', {
    showClass: 'fancybox-fadeIn',
    hideClass: 'fancybox-fadeOut',
    mainClass: 'lightbox',
    template: {
        spinner: Spinner.render({
            className: 'lightbox__spinner',
        }),
    },
    Thumbs: {
        // Thumbnails plugin options go here...
    },
    Image: {
        zoom: false,
    },
    Toolbar: {
        display: ['counter', 'close'],
        items: {
            ...Fancybox.Plugins.Toolbar.defaults.items,
            close: {
                type: 'button',
                label: 'CLOSE',
                class: 'lightbox__toolbar-close',
                html: Icon.render('close', ''),
                attr: { 'data-fancybox-close': '', tabindex: 0 }, //eslint-disable-line
            },
        },
    },
    Carousel: {
        Navigation: {
            prevTpl: Icon.render('chevron-left', 'button__icon lightbox__nav-icon'),
            nextTpl: Icon.render('chevron-right', 'button__icon lightbox__nav-icon'),
            classNames: {
                button: 'button button--icon',
                prev: 'lightbox__nav lightbox__nav--left',
                next: 'lightbox__nav lightbox__nav--right',
            },
        },
    },
});
