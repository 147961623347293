import { Loader } from '@googlemaps/js-api-loader';

import Component from '../component/component';
import './map.scss';

export interface IMapSettings {
    lat: string;
    lng: string;
}

interface IMarkerData {
    lat: string;
    lng: string;
    link: string;
    title: string;
    badge: {
        text: string;
        color?: string;
    };
}

export default class Map extends Component {
    static initSelector: string = '.map';
    public settings: IMapSettings;
    public googleMap: google.maps.Map;
    public latLng: google.maps.LatLng;
    public overlays: google.maps.OverlayView[];
    public markerElements: HTMLElement[];
    public styles: google.maps.MapTypeStyle[];

    constructor(target: HTMLElement) {
        super(target);

        this.settings = $.extend(
            {
                lat: null,
                lng: null,
            },
            this.element.data(),
        );

        this.styles = [
            {
                featureType: 'landscape',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#efe9e1',
                    },
                ],
            },
            {
                featureType: 'landscape.natural.terrain',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#d2e7bf',
                    },
                ],
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#d2e7bf',
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#75cff0',
                    },
                ],
            },
        ];

        this.markerElements = Array.from(this.element[0].querySelectorAll('.map__tag'));

        if (window.gotoAndPlay.googleMapsApiKey) {
            const loader: Loader = new Loader({
                apiKey: window.gotoAndPlay.googleMapsApiKey,
                libraries: ['maps'],
            });

            loader.load().then(() => {
                this.latLng = new google.maps.LatLng(
                    parseFloat(this.settings.lat),
                    parseFloat(this.settings.lng),
                );
                this.initMap();
            });
        } else {
            console.error('No Google Maps API key defined in gotoAndPlay');
        }
    }

    initMap(): void {
        this.googleMap = new google.maps.Map(this.element[0], {
            center: this.latLng,
            zoom: 11,
            scrollwheel: false,
            styles: this.styles,
        });

        this.overlays = this.markerElements.map((markerElement: HTMLElement) => {
            const markerData: IMarkerData = {
                lat: markerElement.dataset.lat || '',
                lng: markerElement.dataset.lng || '',
                link: markerElement.dataset.link || '',
                title: markerElement.dataset.title || '',
                badge: {
                    text: markerElement.dataset.badgeText || '',
                    color: markerElement.dataset.badgeColor || '',
                },
            };

            const markerContent: HTMLElement = document.createElement('div');

            markerContent.className = 'map-tag__container';
            markerContent.innerHTML = `
                <a href="${markerData.link}" class="map-tag__link">
                    <span class="map-tag__title">${markerData.title}</span>
                    <div class="badge map-tag__status ${markerData.badge.color || ''}">
                        <span class="badge__text text-tiny">
                            ${markerData.badge.text}
                        </span>
                    </div>
                </a>
            `;

            markerElement.style.pointerEvents = 'auto'; // Enable click events on the marker

            const linkElement: HTMLAnchorElement | null = markerElement.querySelector('.map-tag__link');

            linkElement?.addEventListener('click', (event: MouseEvent) => {
                event.preventDefault();
                const link: string = markerData.link;

                window.open(link, '_blank');
            });

            const overlay: google.maps.OverlayView = new google.maps.OverlayView();

            overlay.onAdd = (): void => {
                overlay.getPanes().overlayMouseTarget.appendChild(markerElement);
            };

            overlay.draw = (): void => {
                const projection: google.maps.MapCanvasProjection = overlay.getProjection();
                const position: google.maps.Point = projection.fromLatLngToDivPixel(
                    new google.maps.LatLng(
                        parseFloat(markerData.lat),
                        parseFloat(markerData.lng),
                    ),
                );

                markerElement.style.left = `${position.x}px`;
                markerElement.style.top = `${position.y - 50}px`;
            };

            overlay.setMap(this.googleMap);

            return overlay;
        });
    }
}
