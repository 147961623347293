import Component from '../component/component';
import HeaderNavigation from '../header-navigation/header-navigation';
import Helpers from '../helpers/helpers';
import Languages from '../languages/languages';

import './header.scss';

const OPEN_CLASS: string = 'is-open';
const SEARCH_CLASS: string = 'has-open-search';

const animationSpeed: number = 300;

export default class Header extends Component {
    static initSelector: string = '.header';

    readonly isWide: boolean;
    readonly initialHeight: number;
    readonly navigationElement: JQuery;
    readonly languagesElement: JQuery;
    readonly searchButton: JQuery;
    readonly searchInput: JQuery;
    readonly searchForm: JQuery;
    readonly toggleButton: JQuery;
    readonly container: JQuery;
    readonly inner: JQuery;

    private navigation?: HeaderNavigation;
    private languagePicker?: Languages;
    private scrollPosition: number;

    constructor(target: HTMLElement) {
        super(target);

        this.initialHeight = this.element.outerHeight();
        this.isWide = this.element.hasClass('header--is-wide-menu');
        this.container = this.element.find('.header__container');
        this.inner = this.element.find('.header__inner');
        this.navigationElement = this.element.find('.header__navigation');
        this.languagesElement = this.element.find('.header__languages');
        this.toggleButton = this.element.find('.header__toggle');
        this.searchButton = this.element.find('.js-search-toggle');
        this.searchInput = this.element.find('.header__search-input input');
        this.searchForm = this.element.find('.header__search-form');

        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleSearchOutsideClick = this.handleSearchOutsideClick.bind(this);

        if (this.navigationElement.length) {
            this.init();
        }
    }

    get isOpen(): boolean {
        return this.element.hasClass(OPEN_CLASS);
    }

    init(): void {
        this.navigation = HeaderNavigation.create<typeof HeaderNavigation>(this.navigationElement[0]);
        this.navigation.header = this;
        this.navigation.initNavigation();

        if (this.languagesElement.length) {
            this.languagePicker = Languages.create<typeof Languages>(this.languagesElement[0]);
            this.languagePicker.header = this;
        }

        this.toggleButton.on('click', this.handleToggleButtonClick.bind(this));
        this.searchButton.on('click', this.handleSearchButtonClick.bind(this));

        this.inner.on('transitionend', this.handleInnerTransitionEnd.bind(this));
        this.inner.on('keyup', this.handleInnerKeyup.bind(this));

        this.handleScrollClasses();
        $(window).on('scroll', this.handleScroll);
        $(window).on('resize', this.handleResize);
        $(window).on('scroll', this.scrollHead.bind(this));
    }

    scrollHead(): void {
        $(window).scrollTop();

        if ($('.hero-front__content').length > 0) {
            if (this.element.hasClass('header--transparent') && $(document).scrollTop() > $('.hero-front__content')[0].scrollHeight) {
                this.element.removeClass('header--transparent');
            } else if ($(document).scrollTop() < $('.hero-front__content')[0].scrollHeight) {
                this.element.addClass('header--transparent');
            }
        }
    }

    open(): void {
        Helpers.disableScroll();

        this.element.addClass(OPEN_CLASS);
        this.toggleButton.attr('aria-expanded', 'true');
    }

    close(): void {
        this.element.removeClass(OPEN_CLASS);
        this.toggleButton.attr('aria-expanded', 'false');
        this.toggleButton.trigger('focus');

        Helpers.enableScroll();
    }

    toggle(): void {
        if (this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    handleToggleButtonClick(event: JQuery.ClickEvent): void {
        event.preventDefault();

        this.toggle();
    }

    handleInnerTransitionEnd(event: JQuery.TriggeredEvent): void {
        if (event.target === event.currentTarget && (event.originalEvent as TransitionEvent).propertyName === 'visibility') {
            if (this.inner.is(':visible')) {
                this.inner.trigger('focus');
            }
        }
    }

    handleInnerKeyup(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.close();
        }
    }

    handleScroll(): void {
        if (!Helpers.isScrollDisabled()) {
            this.handleScrollClasses();
        }
    }

    handleScrollClasses(): void {
        const currentPosition: number = $(document).scrollTop();

        // Check whether scroll position is at top or not.
        // Can be less than zero when scrolling past top in Mac OS Safari.
        if (currentPosition <= 0) {
            this.element.removeClass('has-scrolled is-scrolling-up');
        } else {
            this.element.addClass('has-scrolled');
        }

        // Check scroll direction.
        // 7 is a magic number so scroll direction would not toggle when scroll jitters.
        if (currentPosition < (this.scrollPosition - 7)) {
            this.element.addClass('is-scrolling-up');
            this.element.removeClass('is-scrolling-down');
        } else if (currentPosition > (this.scrollPosition + 7)) {
            this.element.addClass('is-scrolling-down');
            this.element.removeClass('is-scrolling-up');
        }

        // Save scroll position for next comparison.
        this.scrollPosition = currentPosition;
    }

    handleResize(): void {
        const query: MediaQueryList = window.matchMedia(`only screen and (min-width: ${Helpers.breakPoints.md}px)`);

        // Close burger menu after resize to reset it and enable scroll.
        // This is necessary on tablets, for example,
        // where in portrait mobile menu is used
        // and in landscape desktop menu is used.
        if (query.matches && this.isOpen) {
            this.close();
            this.closeMenu(true);
            this.navigation.closeItems();
        }
    }

    handleSearchOutsideClick(event: JQuery.TriggeredEvent): void {
        const clickInSearchForm: boolean = $(event.target).closest(this.searchForm).length !== 0;
        const clickInSearchTrigger: boolean = $(event.target).closest(this.searchButton).length !== 0;

        if (!(clickInSearchForm || clickInSearchTrigger)) {
            this.closeSearch();
        }
    }

    handleSearchButtonClick(event: JQuery.ClickEvent): void {
        event.preventDefault();

        if (this.element.hasClass(SEARCH_CLASS)) {
            this.closeSearch();
        } else if (!Helpers.isSmallScreen && this.element.hasClass('is-open')) {
            window.setTimeout(this.openSearch.bind(this), animationSpeed * 1.5);
        } else {
            this.openSearch();
        }
    }

    openSearch(): void {
        if (Helpers.isSmallScreen) {
            this.close();
        }

        this.searchForm.removeClass('h-visibility-hidden');
        this.element.addClass(SEARCH_CLASS);
        this.searchButton.attr('aria-expanded', 'true');
        this.searchInput.trigger('focus');
        this.searchButton.attr('aria-label', this.searchButton.data('open-text'));

        $(document).off('click', this.handleSearchOutsideClick);
        $(document).on('click', this.handleSearchOutsideClick);
    }

    closeSearch(): void {
        this.element.removeClass(SEARCH_CLASS);
        this.searchForm.addClass('h-visibility-hidden');
        this.searchButton.attr('aria-expanded', 'false');
        this.searchButton.attr('aria-label', this.searchButton.data('close-text'));

        $(document).off('click', this.handleSearchOutsideClick);
    }

    trigger(event: string, options?: { [key: string]: number }): void {
        switch (event) {
            case 'open':
                if (!this.element.hasClass(SEARCH_CLASS)) {
                    this.closeSearch();
                }

                if (this.isWide) {
                    this.openMenu(options?.height);
                }
                break;
            case 'close':
                if (!this.element.hasClass(SEARCH_CLASS)) {
                    this.closeSearch();
                }

                if (this.isWide) {
                    this.closeMenu();
                }
                break;
        }
    }

    openMenu(height?: number): void {
        if (!Helpers.isSmallScreen) {
            this.element.addClass('is-open');
            this.container.stop().animate({
                height: this.initialHeight + height,
            }, animationSpeed);
        }
    }

    closeMenu(force?: boolean): void {
        if (!Helpers.isSmallScreen || force) {
            this.container.stop().animate({
                height: this.initialHeight,
            }, animationSpeed, () => {
                this.element.removeClass('is-open');
            });
        }
    }

    destroy(): void {
        $(window).off('scroll', this.handleScroll);
        $(window).off('resize', this.handleResize);
        $(document).off('click', this.handleSearchOutsideClick);
    }
}
