import { Carousel as FancyCarousel } from '@fancyapps/ui';

import Component from '../component/component';
import Icon from '../icon/icon';

import './carousel.scss';

// For more details about available options and plugins: https://fancyapps.com/docs/ui/carousel

export default class Carousel extends Component {
    static initSelector: string = '.carousel';

    carousel: FancyCarousel;

    constructor(element: HTMLElement) {
        super(element);

        new FancyCarousel(element, {
            friction: 0.88,
            center: false,
            infinite: false,
            ...this.element.data(),
            // we handle slides per page using css
            slidesPerPage: 'auto',
            Navigation: {
                prevTpl: Icon.render('chevron-left', 'button__icon lightbox__nav-icon'),
                nextTpl: Icon.render('chevron-right', 'button__icon lightbox__nav-icon'),
                classNames: {
                    main: 'carousel__nav',
                    button: 'button button--icon carousel__arrow',
                    prev: 'carousel__arrow--prev',
                    next: 'carousel__arrow--next',
                },
            },
            on: {
                init: this.init.bind(this),
            },
        });
    }

    public init(carousel: FancyCarousel): void {
        this.element.addClass('is-initialized');
        this.carousel = carousel;
    }
}

