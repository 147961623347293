import Helpers from '../helpers/helpers';

import './accordion.scss';

export enum AccordionAnimationType {
    slide,
    fadeAndSlide,
}

export enum AccordionAnimationDirection {
    in,
    out,
}

export interface IAccordionSettings {
    openClass?: string;
    accordionClass?: string;
    groupClass?: string;
    controlClass?: string;
    contentClass?: string;
    animationSlideTime?: number;
    animationFadeTime?: number;
    scrollDelay?: number;
    animationSlideEase?: string;
    animationFadeEase?: string;
    animationType?: AccordionAnimationType;
}

export const accordionSettings: IAccordionSettings = {
    accordionClass: 'js-accordion',
    animationFadeEase: 'swing',
    animationFadeTime: 500,
    animationSlideEase: 'swing',
    animationSlideTime: 500,
    animationType: AccordionAnimationType.slide,
    contentClass: 'js-accordion-content',
    controlClass: 'js-accordion-control',
    groupClass: 'js-accordion-group',
    openClass: 'is-open',
    scrollDelay: 500,
};

export default class Accordion {

    settings: IAccordionSettings;
    element: JQuery;

    constructor(target: HTMLElement, settings: IAccordionSettings = {}) {
        this.settings = jQuery.extend(accordionSettings, settings) as IAccordionSettings;
        this.element = $(target);
    }

    getAccordion(): JQuery {
        let accordion: JQuery;

        if (this.element.hasClass(this.settings.accordionClass)) {
            accordion = this.element.addClass(this.settings.accordionClass);
        } else {
            accordion = this.element.parents('.' + this.settings.accordionClass);
        }

        return accordion.length ? $(accordion.get(0)) : accordion;
    }

    toggle(accordion: JQuery = this.getAccordion()): void {
        if (accordion.length) {
            if (accordion.hasClass(this.settings.openClass)) {
                this.close(accordion);
            } else {
                this.open(accordion);
            }
        }
    }

    open(accordion: JQuery = this.getAccordion(), recursive: boolean = false, initialLoad: boolean = false): void {
        if (accordion?.length) {
            this.closeOpen(accordion, recursive);
            const parent: JQuery = accordion.parents('.' + this.settings.accordionClass);

            if (parent.length) {
                const parentAccordion: Accordion = new Accordion(parent.get(0));

                parentAccordion.open(null, true, initialLoad);
            }
            accordion.addClass(this.settings.openClass);
            const content: JQuery = accordion.find('.' + this.settings.contentClass);

            if (content.length) {
                if (!recursive) {
                    setTimeout((): void => {
                        if (!Helpers.isOnScreen($(content.get(0)))) {
                            Helpers.scrollToTarget(accordion);
                        }
                    }, this.settings.scrollDelay);
                }
            }
            if (!recursive && !initialLoad) {
                this.animate(AccordionAnimationDirection.in, accordion);
            }
        }
    }

    close(accordion: JQuery = this.getAccordion(), recursive: boolean = false): void {
        if (accordion.length) {
            if (!recursive) {
                this.animate(AccordionAnimationDirection.out, accordion, (): void => {
                    accordion.removeClass(this.settings.openClass);
                });
            } else {
                accordion.removeClass(this.settings.openClass);
            }
        }
    }

    closeOpen(accordion: JQuery = this.getAccordion(), recursive: boolean = false): void {
        let group: JQuery = accordion.parents('.' + this.settings.groupClass);

        if (group.length) {
            group = $(group.get(0));
            const open: JQuery = group.find('> .' + this.settings.accordionClass + '.' + this.settings.openClass);

            open.each((index: number, element: HTMLElement): void => {
                this.close($(element), recursive);
            });
        }
    }

    animate(type: AccordionAnimationDirection, accordion: JQuery = this.getAccordion(), onComplete: () => void = null): void {
        if (accordion.length) {
            let content: JQuery = accordion.find('.' + this.settings.contentClass);
            const triggerOnComplete: () => void = (): void => {
                content.removeAttr('style');
                if (onComplete) {
                    onComplete();
                }
            };

            if (content.length) {
                const contentHeight: string = content.get(0).offsetHeight + 'px';

                content = content.length ? $(content.get(0)) : content;
                switch (this.settings.animationType) {
                    case AccordionAnimationType.slide:
                        switch (type) {
                            case AccordionAnimationDirection.in:
                                content.stop().css({
                                    height: 0,
                                }).animate({
                                    height: contentHeight,
                                }, this.settings.animationFadeTime, this.settings.animationFadeEase, triggerOnComplete);
                                break;
                            case AccordionAnimationDirection.out:
                                content.stop().animate({
                                    height: 0,
                                }, this.settings.animationSlideTime, this.settings.animationSlideEase, triggerOnComplete);
                                break;
                        }
                        break;
                    case AccordionAnimationType.fadeAndSlide:
                        switch (type) {
                            case AccordionAnimationDirection.in:
                                content.stop().css({
                                    height: 0,
                                    opacity: 0,
                                }).animate({
                                    height: contentHeight,
                                }, this.settings.animationSlideTime, this.settings.animationSlideEase, (): void => {
                                    content.stop().animate({
                                        opacity: 1,
                                    }, this.settings.animationFadeTime, this.settings.animationFadeEase, triggerOnComplete);
                                });
                                break;
                            case AccordionAnimationDirection.out:
                                content.stop().animate({
                                    opacity: 0,
                                }, this.settings.animationFadeTime, this.settings.animationFadeEase, (): void => {
                                    content.stop().animate({
                                        height: 0,
                                    }, this.settings.animationSlideTime, this.settings.animationSlideEase, triggerOnComplete);
                                });
                                break;
                        }
                        break;
                }
            }
        }
    }
}

function onControlClick(event: JQuery.Event): void {
    event.preventDefault();
    const accordion: Accordion = new Accordion(this);

    accordion.toggle();
}

const onLoadHashCheck: () => void = (): void => {
    if (window.location.hash && $(window.location.hash).length && $(window.location.hash).hasClass(accordionSettings.accordionClass)) {
        const accordion: Accordion = new Accordion($(window.location.hash).get(0));

        accordion.open(null, false, true);
    }
};

$((): void => {
    $(document).on('click', '.' + accordionSettings.controlClass, onControlClick);
    onLoadHashCheck();
});
