import Helpers from '../../components/helpers/helpers';

import Ajax from './ajax';

export interface IAjaxPageLoadSettings {
    isPopState?: boolean;
    changeScrollTop?: boolean;
    scrollTop?: number;
}

export interface IScript {
    text?: string;
    src?: string;
}

export interface IAjaxPageResponse {
    bodyClass: string;
    language: string;
    title: string;
    page: string;
    templateName: string;
    headScripts: IScript[];
    footerScripts: IScript[];
    cdata: IGotoAndPlay;
}

export interface IAjaxPageState {
    url: string;
}

export default class AjaxPage {

    public static initialState: IAjaxPageState = {
        url: window.location.href,
    };
    public static hasPushedState: boolean;
    public static current: string;
    public static currentPromise: Promise<void>;
    public static triggerOnLoaded: () => void;
    public static lastScrollTop: number = 0;

    private static cache: AjaxPage[] = [];
    private static xhr: JQueryXHR;

    readonly url: string;

    private jqXHR: JQueryXHR;
    private currentResponse: IAjaxPageResponse;

    constructor(url: string) {
        this.url = url;
    }

    public static get hasHistory(): boolean {
        return !!(window.history?.pushState && window.history?.replaceState);
    }

    public get xhr(): JQueryXHR {
        if (!this.jqXHR) {
            this.jqXHR = Ajax.request({
                data: {
                    ajax: true,
                },
                dataType: 'json',
                type: 'GET',
                url: this.url,
            });
            this.jqXHR.catch((): void => {
                if (AjaxPage.current === this.url) {
                    window.location.href = this.url;
                }
            });
        }

        return this.jqXHR;
    }

    public static isAjaxEvent(event: JQuery.TriggeredEvent): boolean {
        const element: JQuery = $(event.currentTarget);

        return !event.ctrlKey && !event.metaKey && element.attr('target') !== '_blank' && !element.data('no-ajax') && !element.hasClass('no-ajax') && !element.parents('#wpadminbar').length;
    }

    public static preloadPage(url: string): AjaxPage {
        const items: AjaxPage[] = AjaxPage.cache.filter((item: AjaxPage): boolean => item.url === url);

        if (items.length) {
            return items[0];
        } else {
            const item: AjaxPage = new AjaxPage(url);

            if (!this.xhr) {
                this.xhr = item.xhr;
                this.xhr.then((): void => {
                    this.xhr = null;
                });
            }

            AjaxPage.cache.push(item);

            return item;
        }
    }

    public static changePage(url: string, settings: IAjaxPageLoadSettings = {}): Promise<void> {
        return AjaxPage.preloadPage(url).load(settings);
    }

    public load(settings: IAjaxPageLoadSettings): Promise<void> {
        if (window.location.href !== this.url || settings.isPopState) {
            this.startLoading();
            // change scroll top
            if (settings.changeScrollTop) {
                settings.scrollTop = AjaxPage.lastScrollTop;
            }

            AjaxPage.lastScrollTop = $(window).scrollTop();
            // push state
            if (!settings.isPopState) {
                this.pushState();
            }

            AjaxPage.current = this.url;
            AjaxPage.currentPromise = new Promise((): void => {
                this.xhr.then((response: IAjaxPageResponse): void => {
                    this.beforeReplacePage(response, this.currentResponse).then((): void => {
                        if (AjaxPage.current === this.url) {
                            this.updateJavascriptData(response);
                            this.replaceHTMLAttributes(response);
                            this.replaceScripts(response);
                            Helpers.trackPageChange('/' + window.location.href.replace(window.gotoAndPlay.sitePath, ''));
                            this.replacePage(response, this.currentResponse);
                            this.afterReplacePage(response, settings);
                        }
                    });
                });
            });
        }

        return AjaxPage.currentPromise;
    }

    private pushState(): void {
        history.pushState(this.getState(), '', this.url);
        AjaxPage.hasPushedState = true;
    }

    private getState(): IAjaxPageState {
        return {
            url: this.url,
        };
    }

    private updateJavascriptData(response: IAjaxPageResponse): void {
        window.gotoAndPlay = $.extend(window.gotoAndPlay, response.cdata);
    }

    private replaceHTMLAttributes(response: IAjaxPageResponse): void {
        // replace content and body classes
        const keptClasses: string[] = [];
        const body: JQuery = $('body');

        window.gotoAndPlay.ajax.keptBodyClasses.forEach((customClass: string): void => {
            if (body.hasClass(customClass)) {
                keptClasses.push(customClass);
            }
        });
        body.removeClass().addClass(keptClasses.concat(response.bodyClass));
        // replace title
        document.title = response.title;
        // replace lang attr
        $('html').attr('lang', response.language);
    }

    private replaceScripts(response: IAjaxPageResponse): void {
        const head: JQuery = $('head');
        const body: JQuery = $('body');
        const headScripts: string[] = $('head script').toArray().map((script: HTMLScriptElement): string => script.src ? script.src : script.text);
        const footerScripts: string[] = $('body script').toArray().map((script: HTMLScriptElement): string => script.src ? script.src : script.text);

        this.appendScripts(response.headScripts, headScripts, head);
        this.appendScripts(response.footerScripts, footerScripts, body);
    }

    private appendScripts(responseScripts: IScript[], existingScripts: string[], location: JQuery): void {
        responseScripts.forEach((script: IScript): void => {
            if (script.src && existingScripts.indexOf(script.src) === -1) {
                location.append(`<script src="${script.src}" />`);
            }

            if (script.text && existingScripts.indexOf(script.text) === -1) {
                location.append(`<script>${script.text}</script>`);
            }
        });
    }

    private startLoading(): void {
        $('body').addClass('is-loading');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private beforeReplacePage(nextPage: IAjaxPageResponse, currentPage?: IAjaxPageResponse): Promise<void> {
        return new Promise((resolve: () => void): void => {
            // add animation logic here and then resolve
            resolve();
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private replacePage(nextPage: IAjaxPageResponse, currentPage?: IAjaxPageResponse): void {
        // destroy global events
        $(document).trigger('destroy');
        // replace page html
        $('#page').html(nextPage.page);
        // init global events
        $(document).trigger('enhance');
    }

    private afterReplacePage(response: IAjaxPageResponse, settings: IAjaxPageLoadSettings): void {
        this.currentResponse = response;
        $('body').removeClass('is-loading');

        // scroll to top
        $(window).scrollTop(0);
        // update scroll position if needed
        window.setTimeout((): void => {
            if (settings.scrollTop) {
                $(window).scrollTop(settings.scrollTop);
            }

            if (AjaxPage.triggerOnLoaded) {
                AjaxPage.triggerOnLoaded();
                AjaxPage.triggerOnLoaded = null;
            }

            this.endLoading();
        });
    }

    private endLoading(): void {
        $('body').addClass('is-loaded');
    }
}

if (AjaxPage.hasHistory && window.gotoAndPlay.ajax.isEnabled) {
    history.replaceState(AjaxPage.initialState, document.title, AjaxPage.initialState.url);
    $(document).on('mouseenter', 'a[href^="' + window.gotoAndPlay.sitePath.replace(/\/$/, '') + '"]', (event: JQuery.TriggeredEvent): void => {
        if (AjaxPage.isAjaxEvent(event)) {
            event.preventDefault();
            AjaxPage.preloadPage($(event.currentTarget).attr('href'));
        }
    });

    $(document).on('click', 'a[href^="' + window.gotoAndPlay.sitePath.replace(/\/$/, '') + '"]', (event: JQuery.TriggeredEvent): void => {
        if (AjaxPage.isAjaxEvent(event)) {
            event.preventDefault();
            AjaxPage.changePage($(event.currentTarget).attr('href'));
        }
    });

    window.addEventListener('popstate', (event: PopStateEvent): void => {
        try {
            if (event.state?.url && AjaxPage.current !== window.location.href.split('#')[0]) {
                AjaxPage.changePage(event.state.url, {
                    changeScrollTop: true,
                    isPopState: true,
                });
            }
        } catch (exception) {
            console.warn(`[Warning] ajax page change not possible.`);
            window.location.reload();
        }
    });
}
