import './public-path';

/**
 * Polyfills
 */
import 'core-js/features/function/name';
import 'core-js/features/object/assign';
import 'core-js/features/promise';

/**
 * Globals.
 */
import './components/reset/reset';
import './components/typography/typography';

window.gotoAndPlay.components = {};

// require all .ts files in utilities directory
const utilities: __WebpackModuleApi.RequireContext = require.context('./utilities/', true, /^\.\/.*(?<!--example)\.ts$/);

utilities.keys().forEach((key: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const component: any = utilities(key);

    if (component.default) {
        window.gotoAndPlay.components[component.default.name] = component.default;
    }
});

// require all .ts files in components directory
const components: __WebpackModuleApi.RequireContext = require.context('./components/', true, /^\.\/.*(?<!--example)\.ts$/);

components.keys().forEach((key: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const component: any = components(key);

    if (component.default) {
        window.gotoAndPlay.components[component.default.name] = component.default;
    }
});

// require all .ts files in modules directory
const modules: __WebpackModuleApi.RequireContext = require.context('./modules/', true, /^\.\/.*(?<!--example)\.ts$/);

modules.keys().forEach((key: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const component: any = modules(key);

    if (component.default) {
        window.gotoAndPlay.components[component.default.name] = component.default;
    }
});

// require all .ts files in views directory
const views: __WebpackModuleApi.RequireContext = require.context('./views/', true, /^\.\/.*(?<!--example)\.ts$/);

views.keys().forEach((key: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const component: any = views(key);

    if (component.default) {
        window.gotoAndPlay.components[component.default.name] = component.default;
    }
});
