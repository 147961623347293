import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/splide/dist/css/splide.min.css';

import Component from '../../components/component/component';
import Helpers, { debounce } from '../../components/helpers/helpers';

import './image-carousel.scss';

export default class ImageCarousel extends Component {
    static initSelector: string = '.image-carousel';

    private debounceScroll: debounce<() => void>;

    sliderElement: JQuery;
    modalSliderElement: JQuery;
    slider: Splide;
    modalSlider: Splide;
    currentSlide: JQuery;
    closeModalLink: JQuery;
    prevButton: JQuery;
    nextButton: JQuery;
    openModalLink: JQuery;

    constructor(element: HTMLElement) {
        super(element);

        this.sliderElement = this.element.find('.image-carousel__slider');
        this.modalSliderElement = this.element.find('.image-carousel__modal-slider');
        this.currentSlide = this.element.find('.js-current-slide');
        this.closeModalLink = this.element.find('.image-carousel__close-link');
        this.debounceScroll = debounce(10, this.scrollHandler.bind(this), {atBegin: true});
        this.openModalLink = this.element.find('.image-carousel__image-link');

        this.prevButton = this.element.find('.js-prev');
        this.nextButton = this.element.find('.js-next');

        this.init();
    }

    init(): void {
        this.slider = new Splide(
            this.sliderElement.get(0),
            {
                autoWidth: true,
                type: 'loop',
                drag: true,
                focus: 'center',
                perPage: 5,
                arrows: false,
                pagination: false,
                clones: 5,
                cloneStatus: true,
                gap: '16px',
                classes: {
                },
                autoScroll: {
                    speed: 1,
                },
                breakpoints: {
                    [Helpers.breakPoints.sm]: {
                        perPage: 2,
                        drag: true,
                    },
                },
            },
        );

        this.modalSlider = new Splide(
            this.modalSliderElement.get(0),
            {
                perPage: 1,
                arrows: false,
                pagination: false,
            },
        );

        this.modalSlider.mount();

        this.modalSlider.on('move', (destIndex: number): void => {
            this.currentSlide.text(destIndex + 1);
        });

        this.prevButton.on('click', (): void => {
            this.modalSlider.go('<');
        });

        this.nextButton.on('click', (): void => {
            this.modalSlider.go('>');
        });

        this.closeModalLink.on('click', (event: JQuery.ClickEvent): void => {
            event.preventDefault();

            $('.image-carousel__modal').find('.modal__close').trigger('click');

            const playTimeout: number = window.setTimeout((): void => {
                this.slider.Components.AutoScroll.play();
                this.modalSliderElement.removeClass('is-visible');

                window.clearTimeout(playTimeout);
            }, 100);

        });

        this.handleSliderActivity();
        const outerTimeOut: number = window.setTimeout((): void => {
            this.openModalLink = this.element.find('.image-carousel__image-link');

            this.openModalLink.on('click', (event: JQuery.ClickEvent): void => {
                const index: number = $(event.currentTarget).data('index');

                const innerTimeout: number = window.setTimeout((): void => {
                    this.modalSlider.go(index);
                    this.modalSliderElement.addClass('is-visible');

                    window.clearTimeout(innerTimeout);
                }, 100);
            });

            window.clearTimeout(outerTimeOut);
        }, 100);

        $(window).on('scroll', this.debounceScroll);
    }

    handleSliderActivity(): void {
        if (Helpers.isOnScreen(this.sliderElement) && !this.sliderElement.hasClass('is-active')) {
            this.slider.mount({AutoScroll});
        }
    }

    scrollHandler(): void {
        if (!Helpers.isScrollDisabled()) {
            this.handleSliderActivity();
        }
    }

    destroy(): void {
        $(document).off('scroll', this.debounceScroll);

        this.debounceScroll.cancel();
    }

}
