import { debounce } from 'throttle-debounce';

import Component from '../../components/component/component';
import Helpers from '../../components/helpers/helpers';

import './logo-module.scss';

export default class LogoModule extends Component {
    static initSelector: string = '.logo-module__scroller-wrapper';

    readonly scroller: JQuery;
    readonly scrollTime: number;
    readonly debounceUpdate: debounce<() => void>;
    readonly debounceLoad: debounce<() => void>;
    readonly debounceScrollForward: debounce<() => void>;

    private scrollNeed: number;
    private duplicate: JQuery;

    constructor(element: HTMLElement) {
        super(element);

        this.scroller = this.element.find('.logo-module__scroller');
        this.scrollTime = (this.element.find('.logo-module__logo-wrapper').length * 750) * 2;

        this.scrollForward = this.scrollForward.bind(this);
        this.pauseScrolling = this.pauseScrolling.bind(this);
        this.maybeStartScrolling = this.maybeStartScrolling.bind(this);
        this.debounceUpdate = debounce(500, this.maybeStartScrolling);
        this.debounceLoad = debounce(500, this.maybeStartScrolling);
        this.debounceScrollForward = debounce(500, this.scrollForward);

        $(window).on('resize', this.debounceUpdate);
        $(window).on('scroll', this.debounceUpdate);

        this.element.on('mouseenter', this.pauseScrolling);
        this.element.on('mouseleave', this.maybeStartScrolling);
        this.element.find('img').on('load', this.debounceLoad);
        this.maybeStartScrolling();
    }

    stopScrolling(): void {
        this.element.stop();
    }

    scrollForward(): void {
        this.scrollNeed = (this.element[0].scrollWidth / 2) + 12; // +12px is half of the margin between two scrollers
        const currentScroll: number = this.element.scrollLeft();
        const timeMultiplier: number = (this.scrollNeed - currentScroll) / this.scrollNeed;

        this.element.stop();
        this.element.animate({ scrollLeft: this.scrollNeed }, this.scrollTime * timeMultiplier, 'linear', this.scrollBack.bind(this));
    }

    scrollBack(): void {
        this.element.scrollLeft(0);
        this.debounceScrollForward();
    }

    createDuplicateElements(): void {
        this.element.addClass('is-scrolling');

        if (!this.duplicate) {
            this.duplicate = this.scroller.clone();
            const images: JQuery = this.duplicate.find('.lazyautosizes');

            images.removeClass('.lazyloading');
            images.removeClass('.lazyautosizes');
            images.addClass('lazyload');
            images.addClass('lazypreload');
            this.duplicate.appendTo(this.element);
            this.duplicate.find('img').on('load', this.debounceLoad);
        }
    }

    clearDuplicateElements(): void {
        this.element.removeClass('is-scrolling');

        if (this.duplicate) {
            this.duplicate.detach();
            this.duplicate = null;
        }
    }

    pauseScrolling(): void {
        this.element.stop();
    }

    get isOverflowing(): boolean {
        return this.scroller[0].scrollWidth > this.element.innerWidth();
    }

    maybeStartScrolling(): void {
        if (this.element.find('img').length > 0) {
            if (this.isOverflowing) {
                this.createDuplicateElements();

                if (Helpers.isOnScreen(this.element)) {
                    this.debounceScrollForward();
                } else {
                    this.pauseScrolling();
                }
            } else {
                this.stopScrolling();
                this.clearDuplicateElements();
            }
        }
    }
}

