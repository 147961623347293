import './spinner.scss';

export interface ISpinner {
    label?: string;
}

export interface ISpinnerProps {
    data?: ISpinner;
    className?: string;
}

export default class Spinner {
    static render(props?: ISpinnerProps): string {
        const { data = {}, className }: ISpinnerProps = props;
        const { label = 'Loading' }: ISpinner = data;
        const classArray: string[] = ['spinner'];

        if (className) {
            classArray.push(className);
        }

        return '<div class="' + classArray.join(' ') + '" aria-label="' + label + '"><svg class="spinner__circle" viewBox="25 25 50 50"><circle class="spinner__path" cx="50" cy="50" r="22" fill="none"></circle></svg></div>';
    }
}
