export default class Cookie {
    static set(cookieName: string, cookieValue: string, expireHours: number = 24 * 30): void {
        const d: Date = new Date();

        d.setTime(d.getTime() + ((expireHours ? expireHours : 1) * 60 * 60 * 1000));
        const expires: string = 'expires=' + d.toUTCString();
        const domain: string = (window.gotoAndPlay.cookieDomain ? ';domain=' + window.gotoAndPlay.cookieDomain : '');

        document.cookie = cookieName + '=' + cookieValue + '; ' + expires + '; path=' + window.gotoAndPlay.cookiePath + domain;
    }

    static get(cookieName: string): string {
        const name: string = cookieName + '=';
        const ca: string[] = document.cookie.split(';');

        for (let c of ca) {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return '';
    }
}
