import Ajax from '../../modules/ajax/ajax';
import Component from '../component/component';
import {debounce} from '../helpers/helpers';

import './imports/example.mp4';

import './video.scss';

export interface IVideoSettings {
    videoHolder?: string;
    playingClass?: string;
    videoImage?: string;
    autoplayClass?: string;
    videoClass?: string;
    bodyPlayingClass?: string;
    readyClass?: string;
    buttonClass?: string;
}

export default class Video extends Component {
    static initSelector: string = '.js-video';

    private debouncedScrollHandler: debounce<() => void>;

    static readyClass: string = 'is-ready';
    static videoClass: string = 'video';
    static loadingClass: string = 'is-loading';
    static pausedClass: string = 'is-paused';

    settings: IVideoSettings;
    vimeoOptions: any; // eslint-disable-line
    iframe: JQuery;
    player: any; // eslint-disable-line
    initialized: boolean;
    checkInitialized: boolean;
    videoContainer: JQuery;
    videoId?: string;
    toggleButton: JQuery;
    modalButton: JQuery;
    isPlaying: boolean;
    reducedMotion: boolean;

    private url: string;
    private videoOverlay: JQuery;
    private autoplay: boolean;

    constructor(target: HTMLElement) {
        super(target);

        this.settings = {
            autoplayClass: 'has-autoplay',
            bodyPlayingClass: 'video-playing',
            buttonClass: 'js-video-play',
            readyClass: Video.readyClass,
            videoClass: 'video__video',
            videoHolder: 'video__figure',
            videoImage: 'video__image',
        };

        this.videoContainer = this.element.find('.' + this.settings.videoHolder);
        this.iframe = this.element.find('iframe');
        this.url = this.videoContainer.data('src');
        this.videoOverlay = this.element.find(`.video__overlay`);
        this.autoplay = (Number(this.element.find(`[data-autoplay]`).data('autoplay')) === 1) ? true : false;
        this.toggleButton = this.element.find('.video__button--toggle');
        this.modalButton = this.element.find('.video__button--modal');
        this.isPlaying = false;
        this.reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

        this.debouncedScrollHandler = debounce(150, this.loadVideoOnScroll.bind(this), { atBegin: false });

        if (this.videoOverlay.length > 0 && !this.autoplay) {
            this.videoOverlay.on('click', this.initVideos.bind(this));
        }

        if (this.autoplay) {
            this.debouncedScrollHandler();
            $(document).on('scroll', this.debouncedScrollHandler);
        }
    }

    setVimeoOptions(url: string): void {
        this.vimeoOptions = {
            autoplay: true,
            // disable sound if this is moving cover
            background: this.autoplay,
            byline: false,
            loop: true,
            title: false,
            url,
        };
    }

    loadVideoOnScroll(): void {
        const currentWindowPos: number = $(window).scrollTop() + $(window).height();

        this.videoContainer.each((index: number, video: HTMLElement) => {
            const videoOffset: number = $(video).offset().top;
            const hasOverlayHidden: boolean = $(video).next().hasClass('is-hidden');

            if (currentWindowPos >= videoOffset && !hasOverlayHidden) {
                this.destroy();
                this.videoLoader();
            }
        });
    }

    destroy(): void {
        $(document).off('scroll', this.debouncedScrollHandler);

        this.debouncedScrollHandler.cancel();
    }

    modalButtonHandler(): void {
        if (this.isPlaying) {
            this.pauseVideoPlayback();
        }
    }

    removeVideoOverlay(): void {
        this.element.find(`.video__image`).addClass('is-hidden');
        this.videoOverlay.addClass('is-hidden');
    }

    pauseVideoPlayback(): void {
        if (this.isVimeo(this.url)) {
            this.player.pause();
        } else {
            const videoElement: HTMLVideoElement = this.element.find('video')[0];

            videoElement.pause();
        }

        this.isPlaying = false;
        this.element.addClass(Video.pausedClass);
    }

    resumeVideoPlayback(): void {
        if (this.isVimeo(this.url)) {
            this.player.play();
        } else {
            const videoElement: HTMLVideoElement = this.element.find('video')[0];

            videoElement.play();
        }

        this.isPlaying = true;
        this.element.removeClass(Video.pausedClass);
    }

    initVideos(event: JQuery.ClickEvent): void {
        event.preventDefault();

        this.videoOverlay.off('click');

        this.videoLoader();
    }

    videoLoader(): void {
        if (this.isYoutube(this.url)) {
            this.toggleButton.addClass('h-hidden');
            this.modalButton.addClass('h-hidden');

            if ((this.autoplay && !this.reducedMotion) || !this.autoplay) {
                this.element.addClass(Video.loadingClass);
                this.loadYoutubeVideo(this.url);
            } else {
                this.videoOverlay.on('click', () => {
                    this.loadYoutubeVideo(this.url);
                });
            }
        } else if (this.isVimeo(this.url)) {
            this.element.addClass(Video.loadingClass);
            this.loadVimeoVideo(this.url);

            if (this.autoplay) {
                this.toggleButton.on('click', this.toggleHandler.bind(this));
                this.modalButton.on('click', this.modalButtonHandler.bind(this));
            }
        } else {
            this.element.addClass(Video.loadingClass);
            this.loadLocalVideo(this.url);

            if (this.autoplay) {
                this.toggleButton.on('click', this.toggleHandler.bind(this));
                this.modalButton.on('click', this.modalButtonHandler.bind(this));

                if (this.reducedMotion) {
                    const videoElement: HTMLVideoElement = this.element.find('video')[0];

                    videoElement.pause();
                    this.isPlaying = false;
                    this.element.addClass(Video.pausedClass);
                }
            }
        }
    }

    toggleHandler(): void {
        if (this.isPlaying) {
            this.isPlaying = false;
            this.pauseVideoPlayback();
        } else {
            this.isPlaying = true;
            this.resumeVideoPlayback();
        }
    }

    isYoutube(url: string): boolean {
        // If is either youtu.be or youtube link
        return url.includes('youtu');
    }

    isVimeo(url: string): boolean {
        return url.includes('vimeo');
    }

    loadLocalVideo(url: string): void {
        const videoContainer: JQuery = this.element.find('.video__video');

        if (videoContainer.find('.video').length === 0) {
            const videoElement: JQuery = $(`<video width="100%" height="100%" autoplay${this.autoplay ? ' muted loop playsinline' : ' controls'}><source src="${url}" type="video/mp4"></video>`);

            videoContainer.append(videoElement);

            videoElement[0].addEventListener('loadedmetadata', () => {
                this.element.addClass(Video.readyClass);
                this.element.removeClass(Video.loadingClass);
                this.removeVideoOverlay();
                this.isPlaying = true;
            }, false);
        } else {
            this.element.addClass(Video.readyClass);
            this.element.removeClass(Video.loadingClass);
            this.removeVideoOverlay();
            this.isPlaying = true;
        }
    }

    loadYoutubeVideo(url: string): void {
        const isMuted: string = this.autoplay ? '&mute=1' : '' ;
        const iframe: JQuery = $(`<iframe width="100%" height="100%" src="https://www.youtube.com/embed/${this.getYoutubeLinkId(url)}?autoplay=1${isMuted}"></iframe>`);
        const iFrameContainer: JQuery = this.element.find('.video__video');

        iFrameContainer.append(iframe);
        this.element.addClass(Video.readyClass);
        this.element.removeClass(Video.loadingClass);
        this.removeVideoOverlay();
        this.isPlaying = true;
    }

    getYoutubeLinkId(url: string): string {
        if (url.includes('youtu.be/')) {
            const videoIdfromShareLink: string = url.split('.be/')[1];

            return videoIdfromShareLink;
        }

        if (url.includes('youtube.com/embed/')) {
            const videoIdfromEmbed: string = url.split('/embed/')[1];

            return videoIdfromEmbed;
        }

        const videoId: string = url.split('v=')[1];
        const ampersandPosition: number = videoId.indexOf('&');

        if (ampersandPosition !== -1) {
            return videoId.substring(0, ampersandPosition);
        }

        return videoId;
    }

    loadVimeoVideo(url: string): void {
        $.ajax({
            async: false,
            // eslint-disable-next-line
            success: (response: any) => {
                if (response.video_id) {
                    const videoUrl: string = this.videoContainer.data('src');

                    this.player = null;
                    gotoAndPlay.vimeoApiLoaded = false;
                    this.setVimeoOptions(videoUrl);
                    const uniqueId: string = Math.random().toString(36).substr(2, 9);

                    this.videoId = 'video_' + uniqueId + response.video_id;
                    this.element.find(`[data-vimeo-initialized]`).removeAttr('data-vimeo-initialized');
                    this.element.find('.' + this.settings.videoClass).attr('id', this.videoId);
                    this.init();
                }
            },
            url: 'https://vimeo.com/api/oembed.json?url=' + url,
        });
    }

    init(): void {
        if (!gotoAndPlay.vimeoApiLoaded) {
            this.loadVimeoAPI().done(() => {
                gotoAndPlay.vimeoApiLoaded = true;
                this.checkAutoPlay();
            });
        } else {
            this.checkAutoPlay();
        }
    }

    loadVimeoAPI(): JQueryXHR {
        return Ajax.loadScript('https://player.vimeo.com/api/player.js');
    }

    checkAutoPlay(): void {
        this.checkInitialized = true;
        if (gotoAndPlay.vimeoApiLoaded && this.iframe) {
            try {
                this.player = new Vimeo.Player(this.videoId, this.vimeoOptions);
            } catch (error) {
                this.player = null;
            }

            if (this.player) {
                this.player.on('timeupdate', (data: { seconds: number, percent: number, duration: number }) => {
                    if (data.seconds > 0 && !this.element.hasClass(this.settings.readyClass)) {
                        this.element.addClass(this.settings.readyClass);
                        this.removeVideoOverlay();
                        this.element.removeClass(Video.loadingClass);

                        if (this.autoplay && this.reducedMotion) {
                            this.player.pause();
                            this.element.addClass(Video.pausedClass);
                        } else {
                            this.isPlaying = true;
                        }
                    }
                });
            }
        }
    }
}
