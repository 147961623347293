import Helpers from '../../components/helpers/helpers';

export * from './ajax-page';

export interface IAjaxPostParams {
    action: string;
    nonce?: string;
    isPublic?: boolean;

    [propName: string]: string | number | Record<string, unknown> | boolean;
}

export default class Ajax {

    public static post(data: FormData | IAjaxPostParams, settings: JQuery.AjaxSettings = {}): JQueryXHR {
        let processData: boolean = true;

        if (data instanceof FormData) {
            data.append('nonce', window.gotoAndPlay.nonce);
            data.append('isPublic', '1');
            processData = false;
        } else {
            data.nonce = window.gotoAndPlay.nonce;
            data.isPublic = false;
        }

        return this.request({
            data,
            dataType: 'json',
            processData,
            type: 'POST',
            url: window.gotoAndPlay.ajaxPath,
            ...settings,
        });
    }

    public static get(url: string, settings: JQuery.AjaxSettings = {}): JQueryXHR {
        return this.request({
            type: 'GET',
            url,
            ...settings,
        });
    }

    public static request(settings: JQuery.AjaxSettings): JQueryXHR {
        return $.ajax({
            ...settings,
        });
    }

    public static loadScript(url: string): JQueryXHR {
        if (Helpers.isURL(url)) {
            return $.getScript(url);
        } else {
            return $.getScript(window.gotoAndPlay.templatePath + 'inc/js/import/' + url + '.' + window.gotoAndPlay.version + '.js');
        }
    }
}
