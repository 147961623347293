import Cookie from '../../../utilities/cookie/cookie';
import Notification, { INotification } from '../notification/notification';

export default class CookieNotification extends Notification {
    static initSelector: string = '.cookie-notification';

    public static get shouldShow(): boolean {
        return Cookie.get('gotoandplay_cookie_agreement') !== 'accepted';
    }

    public static render(data: INotification, className: string = ''): JQuery {
        return super.render(data, ['cookie-notification', className].join(' '));
    }

    remove(): void {
        Cookie.set('gotoandplay_cookie_agreement', 'accepted', 365 * 24);

        super.remove();
    }
}
