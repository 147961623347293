import Component from '../component/component';
import Header from '../header/header';

import './languages.scss';

interface ILanguageSettings {
    activeClass: string;
    topClass: string;
}

const animationSpeed: number = 300;

export default class Languages extends Component {
    static initSelector: string = '.js-languages';

    readonly trigger: JQuery;
    readonly settings: ILanguageSettings;
    readonly list: JQuery;

    public header: Header;

    constructor(target: HTMLElement) {
        super(target);

        this.trigger = this.element.find('.languages__trigger');
        this.list = this.element.find('.languages__list');

        this.settings = {
            activeClass: 'is-active',
            topClass: 'to-top',
        };

        this.init();
    }

    init(): void {
        this.documentClickHandler = this.documentClickHandler.bind(this);
        this.trigger.on('click', this.toggleHandler.bind(this));
        this.element.on('keyup.languages', this.keyboardHandler.bind(this));
    }

    addEventListeners(): void {
        $(document).on('click.languages', this.documentClickHandler);
    }

    documentClickHandler(event: JQuery.TriggeredEvent): void {
        const closestLink: boolean = !!$(event.target).closest('.header-navigation__item').length;
        const isNotLanguage: boolean = $(event.target).closest(this.element).length === 0;

        if (closestLink && isNotLanguage) {
            this.beginClose(true);
        } else if (isNotLanguage) {
            this.beginClose();
        }
    }

    toggleHandler(event: Event): void {
        event.preventDefault();

        if (this.element.hasClass(this.settings.activeClass)) {
            this.beginClose();
        } else {
            this.beginOpen();
        }
    }

    keyboardHandler(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.close();
            this.trigger.focus();
        }
    }

    beginOpen(): void {
        this.list.removeClass('h-visibility-hidden');
        if (this.getListDirection() === 'top') {
            this.element.addClass(this.settings.topClass);
        } else {
            this.element.removeClass(this.settings.topClass);
        }

        if (this.header?.isWide) {
            this.openWithAnimation();
        } else {
            this.open();
        }
    }

    openWithAnimation(): void {
        if (this.header) {
            this.header.trigger('open', { height: this.list[0].scrollHeight });
        }

        window.setTimeout(() => {
            this.list.stop().animate({ opacity: 1 }, animationSpeed, (): void => {
                this.open();
            });
        }, 0);
    }

    open(): void {
        this.trigger.attr('aria-expanded', 'true');
        this.element.addClass(this.settings.activeClass);
        this.addEventListeners();
    }

    beginClose(keepMenuOpen?: boolean): void {
        if (this.header?.isWide) {
            this.closeWithAnimation(keepMenuOpen);
        } else {
            this.close(keepMenuOpen);
        }
    }

    closeWithAnimation(keepMenuOpen?: boolean): void {
        this.list.animate({ opacity: 0 }, animationSpeed, () => {
            this.close(keepMenuOpen);
        });
    }

    close(keepMenuOpen?: boolean): void {
        this.list.addClass('h-visibility-hidden');
        this.element.removeClass(this.settings.activeClass);
        this.trigger.attr('aria-expanded', 'false');

        if (this.header && !keepMenuOpen) {
            this.header.trigger('close');
        }

        this.destroy();
    }

    getListDirection(): 'top' | 'bottom' {
        const elemTop: number = this.element.offset().top;
        const windowTop: number = $(window).scrollTop();
        const windowHeight: number = $(window).height();
        const elemHeight: number = this.element.outerHeight();
        const ddHeight: number = this.element.find('.languages__list').outerHeight();

        if ((elemTop - windowTop + elemHeight + ddHeight) > windowHeight) {
            return 'top';
        } else {
            return 'bottom';
        }
    }

    destroy(): void {
        $(document).off('click.languages', this.documentClickHandler);
    }
}
