import './button.scss';

import Icon from '../icon/icon';

export interface IButton {
    text: string;
    icon?: string;
    link?: string;
    iconPosition?: string;
    attributes?: string;
}

export interface IButtonProps {
    data: IButton;
    className?: string;
    iconClassName?: string;
}

export default class Button {
    static render(props: IButtonProps): string {
        const { data, className, iconClassName }: IButtonProps = props;

        const element: string = data.link ? 'a' : 'button';
        const icon: string = data.icon ? Icon.render(data.icon, `button__icon${iconClassName ? ` ${iconClassName}` : ''}`) : '';
        const classArray: string[] = ['button'];

        if (className) {
            classArray.push(className);
        }

        if (data.icon && !data.iconPosition) {
            classArray.push('button--icon');
        }

        if (data.icon && data.iconPosition === 'right') {
            classArray.push('button--icon-right');
        }

        if (data.icon && data.iconPosition === 'left') {
            classArray.push('button--icon-left');
        }

        return `<${element} class="${classArray.join(' ')}" ${data.link ? `href="${data.link}"` : 'type="button"'}${data.attributes ? ` ${data.attributes}` : ''}><span class="button__inner">${icon}<span class="button__text">${data.text}</span></span></${element}>`;
    }
}
