import Button, { IButton } from '../../button/button';
import Component from '../../component/component';

import './notification.scss';

export interface INotification {
    content: string;
    button: IButton;
    actions?: IActions[];
    modifier?: string;
}

export interface IActions {
    button: IButton;
    class?: string;
}

export default class Notification extends Component {
    static initSelector: string = '.notification';

    closeButton: JQuery;

    constructor(element: HTMLElement) {
        super(element);

        this.closeButton = this.element.find('.js-notification-close');

        this.init();
    }

    public static get shouldShow(): boolean {
        return false;
    }

    public static render(data: INotification, className: string = ''): JQuery {
        const block: JQuery = $('<div class="notification ' + className + '" role="status" aria-live="polite" />');
        const contentElement: JQuery = $('<div class="notification__content text" />');
        const actionsElement: JQuery = $('<div class="notification__action-list" />');
        const closeButton: JQuery = $(Button.render({
            className: 'button--text notification__action js-notification-close',
            data: data.button,
        }));

        if (data.actions) {
            for (const action of data.actions) {
                actionsElement.append($(Button.render({
                    className: 'button--text notification__action ' + action.class,
                    data: action.button,
                })));
            }
        }

        actionsElement.append(closeButton);
        contentElement.append(data.content);
        block.append(contentElement);
        block.append(actionsElement);

        return block;
    }

    init(): void {
        this.closeButton.on('click', this.handleCloseClick);
    }

    handleCloseClick: (event: JQuery.ClickEvent<HTMLButtonElement>) => void = (event: JQuery.ClickEvent<HTMLButtonElement>) => {
        event.preventDefault();

        this.hide();

        this.element.on('transitionend', (e: JQuery.TriggeredEvent) => {
            if (e.target === e.currentTarget && (e.originalEvent as TransitionEvent).propertyName === 'transform') {
                this.remove();
            }
        });
    };

    show(): void {
        window.setTimeout(() => {
            this.element.addClass('is-visible');
        });
    }

    hide(): void {
        this.element.removeClass('is-visible');
    }

    remove(): void {
        this.element.remove();
    }
}
