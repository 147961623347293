import Component from '../component/component';
import Header from '../header/header';
import Helpers from '../helpers/helpers';

import './header-navigation.scss';

const animationSpeed: number = 300;

export default class HeaderNavigation extends Component {
    static initSelector: string = '.js-header-navigation';

    readonly isWide: boolean;

    public header: Header;
    public initHeight: number;

    private items: HeaderNavigationItem[];

    constructor(target: HTMLElement) {
        super(target);

        this.isWide = this.element.hasClass('.header-navigation--is-wide-menu');
    }

    public initNavigation(): void {
        this.items = this.element.find('.header-navigation__item.has-children')
            .map((index: number, element: HTMLElement) => {
                const headerItem: HeaderNavigationItem = HeaderNavigationItem.create<typeof HeaderNavigationItem>(element);

                headerItem.header = this.header;

                return headerItem;
            })
            .toArray();

        if (this.isWide) {
            this.initHeight = this.element.find('.navigation__list').first().outerHeight();
        }
    }

    public closeItems(): void {
        for (const item of this.items) {
            item.close(false);
        }
    }
}

class HeaderNavigationItem extends Component {
    static initSelector: string = '.js-header-navigation-item';

    readonly isWide: boolean;

    public isOpen: boolean = false;
    public link: JQuery;
    public list: JQuery;
    public header: Header;

    private menuOpen: boolean;

    constructor(target: HTMLElement) {
        super(target);

        this.isWide = this.element.parents('.header-navigation').hasClass('header-navigation--is-wide-menu');
        this.link = this.element.children('.header-navigation__link');
        this.list = this.element.children('.header-navigation__list--child');
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.menuOpen = false;

        this.element.on('keyup.header-navigationItem', this.handleKeyup.bind(this));
        this.link.on('click', this.toggle.bind(this));
    }

    open(): void {
        this.isOpen = true;
        this.list.removeClass('h-visibility-hidden');

        if (Helpers.isSmallScreen || this.isWide) {
            this.openWithAnimation();
        } else {
            this.finishOpen();
        }

        $(document).on('click.header-navigationItem', this.handleOutsideClick);
    }

    finishOpen(): void {
        this.menuOpen = true;
        this.element.addClass('is-open');
        this.element.children('.header-navigation__link').attr('aria-expanded', 'true');
    }

    openWithAnimation(): void {
        const dropdownHeight: number = this.list[0].scrollHeight;

        if (this.isWide) {
            this.header.trigger('open', { height: dropdownHeight });
        }

        this.list.stop().animate({
            height: dropdownHeight,
        }, animationSpeed, (): void => {
            this.list.stop().animate({
                opacity: 1,
            }, animationSpeed, () => {
                this.list.removeAttr('style');
                this.finishOpen();
            });
        });
    }

    close(shouldFocusAfterClose: boolean, keepMenuOpen?: boolean): void {
        this.isOpen = false;

        // on small screens, close with slide animation
        if (Helpers.isSmallScreen || this.isWide) {
            this.closeWithAnimation(shouldFocusAfterClose, keepMenuOpen);
        } else {
            this.finishClose(shouldFocusAfterClose);
        }

        $(document).off('click.header-navigationItem', this.handleOutsideClick);
    }

    finishClose(shouldFocus: boolean): void {
        this.element.removeClass('is-open');
        this.element.children('.header-navigation__link').attr('aria-expanded', 'false');
        this.list.addClass('h-visibility-hidden');
        if (shouldFocus) {
            this.element.children('.header-navigation__link').focus();
        }
    }

    closeWithAnimation(shouldFocusAfterClose: boolean, keepMenuOpen?: boolean): void {
        this.list.stop().animate({
            opacity: 0,
        }, animationSpeed, (): void => {
            if (this.isWide && !keepMenuOpen) {
                this.header.trigger('close');
            }

            this.list.stop().animate({
                height: 0,
            }, animationSpeed, (): void => {
                this.finishClose(shouldFocusAfterClose);
                this.list.removeAttr('style');
            });
        });
    }

    toggle(event: JQuery.ClickEvent): void {
        event.preventDefault();

        if (this.isOpen) {
            this.close(false);
        } else {
            this.open();
        }
    }

    handleOutsideClick(event: JQuery.TriggeredEvent): void {
        if (!Helpers.isSmallScreen) {
            const closestLink: boolean = !!$(event.target).closest('.header-navigation__item').length;
            const closestLanguages: boolean = !!$(event.target).closest('.header__languages').length;
            const isNotCurrentLink: boolean = $(event.target).closest(this.element).length === 0;

            if (closestLink && isNotCurrentLink || closestLanguages) {
                this.close(false, this.menuOpen);
            } else if (isNotCurrentLink) {
                this.menuOpen = false;
                this.close(false);
            }
        }
    }

    handleKeyup(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.close(true);
        }
    }

    destroy(): void {
        $(document).off('click.header-navigationItem', this.handleOutsideClick);
    }
}
