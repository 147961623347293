import './module-handle.scss';

jQuery(($: JQueryStatic): void => {
    $(document).on('mouseenter', 'a[data-layout]', (event: JQuery.MouseEnterEvent): void => {
        const target: JQuery = $(event.currentTarget);
        const screenshot: string | undefined = $('.module-handle__img[data-layout="' + target.data('layout') + '"]').attr('src');

        if (screenshot) {
            $('<div class="module-handle-hover"><div class="module-handle-hover__inner"><img class="module-handle-hover__img" src="' + screenshot + '"></div></div>').appendTo(target.closest('.acf-fc-popup'));
        }
    });

    $(document).on('mouseleave', 'a[data-layout]', (): void => {
        $('.module-handle-hover').remove();
    });
});
