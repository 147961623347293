import { focusable, FocusableElement } from 'tabbable';

import Component from '../component/component';
import Helpers from '../helpers/helpers';
import Icon from '../icon/icon';

import './modal.scss';

interface IModalSettings {
    closeLabel: string;
    focusableElement?: string;
}

export default class Modal extends Component {
    static initSelector: string = '.js-modal';

    closeButton: JQuery;
    container: JQuery;
    hasContainer: boolean;
    inner: JQuery;
    keyboardHandler: () => void;
    trigger: JQuery;
    isOpen: boolean;
    focusableElements: FocusableElement[];
    firstFocusableElement: FocusableElement;
    lastFocusableElement: FocusableElement;
    settings: JQuery.PlainObject<IModalSettings>;
    timeout: number;

    constructor(target: HTMLElement, trigger: JQuery, open: boolean = true) {
        super(target);

        this.hasContainer = false;
        this.isOpen = false;
        this.container = $('<div class="modal-container"></div>');
        this.inner = $('<div class="modal-container__inner"></div>');
        this.settings = this.element.data();

        this.keyboardHandler = this.keyboard.bind(this);

        if (open) {
            this.open(trigger);
        }
    }

    getGeneralCloseButton(): JQuery {
        if (this.element.data('hide-default-close')) {
            this.closeButton = this.element.find('.js-modal__close-button');
        } else {
            this.closeButton = $(`<button type="button" class="modal__close" aria-label="${this.settings.closeLabel}">${Icon.render('close', 'modal__close-icon')}</button>`);
        }

        return this.closeButton;
    }

    bindEventHandlers(): void {
        this.container.on('click', (event: JQuery.TriggeredEvent): void => {
            if (event.target === event.currentTarget || event.target === this.inner[0]) {
                event.preventDefault();

                this.close();
            }
        });

        this.closeButton.on('click', (event: JQuery.Event): void => {
            event.preventDefault();

            this.close();
        });
    }

    open(trigger: JQuery): void {
        this.trigger = trigger;
        this.getGeneralCloseButton();

        if (!this.hasContainer) {
            this.inner.append(this.element);
            this.container.append(this.inner);

            if (!this.element.data('hide-default-close')) {
                this.element.prepend(this.closeButton);
            }

            $('body').append(this.container);

            this.bindEventHandlers();
            this.hasContainer = true;
        }

        Helpers.disablePageInteractions();
        this.container.addClass('is-visible');

        this.setInitialFocus();
        this.isOpen = true;

        Helpers.disableScroll();

        this.element.on('keyup.modal', this.keyboardHandler);
        this.element.on('keydown.modal', this.keyboardHandler);
    }

    close(): void {
        this.container.removeClass('is-visible');
        Helpers.enablePageInteractions();
        this.isOpen = false;

        this.timeout = window.setTimeout(() => {
            this.trigger.focus();
        }, 10);

        Helpers.enableScroll();

        this.element.off('keyup.modal', this.keyboardHandler);
        this.element.off('keydown.modal', this.keyboardHandler);
    }

    keyboard(event: JQuery.TriggeredEvent): void {
        if (event.type === 'keyup' && event.key === 'Escape') {
            this.close();
        }

        if (event.type === 'keydown') {
            this.focusableElements = focusable(this.element.get(0));
            this.firstFocusableElement = this.focusableElements[0];
            this.lastFocusableElement = this.focusableElements[this.focusableElements.length - 1];

            if (event.key === 'Tab' && !event.shiftKey) {
                this.switchFocusedElements(event, 'next');
            }

            if (event.key === 'Tab' && event.shiftKey) {
                this.switchFocusedElements(event, 'previous');
            }
        }
    }

    destroy(): void {
        clearTimeout(this.timeout);
    }

    private switchFocusedElements(event: JQuery.TriggeredEvent, direction: string): void {
        if (direction === 'next' && event.target.isEqualNode(this.lastFocusableElement)) {
            event.preventDefault();
            this.firstFocusableElement.focus();

            return;
        }

        if (direction === 'previous' && event.target.isEqualNode(this.firstFocusableElement)) {
            event.preventDefault();

            this.lastFocusableElement.focus();
        }
    }

    private setInitialFocus(): void {
        if (typeof this.settings.focusableElement === 'string') {
            const focusableElement: JQuery = this.element.find(`#${this.settings.focusableElement}`);

            if (focusableElement.length > 0) {
                focusableElement.focus();

                return;
            }
        }

        this.element.find('.modal__close').focus();
    }
}

$(document).on('click', '[data-js="open-modal"]', (event: JQuery.TriggeredEvent): void => {
    event.preventDefault();

    const trigger: JQuery = $(event.currentTarget);
    const element: JQuery = $(trigger.attr('href'));
    const type: string = Component.getDataName(Modal.initSelector);
    const dataAttr: string = type + 'Class';

    if (element.data(dataAttr)) {
        const instance: Modal = element.data(dataAttr);

        instance.open(trigger);
    } else {
        const instance: Modal = new Modal(element[0], trigger, true);

        element.data(dataAttr, instance);
    }
});
