import AirDatepicker, {AirDatepickerLocale} from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

import Component from '../../component/component';
import Icon from '../../icon/icon';

import './datepicker.scss';

export interface IDatepickerLocals {
    [key: string]: AirDatepickerLocale;
}

export default class Datepicker extends Component {
    static initSelector: string = '.datepicker-field';

    private input: JQuery;
    private datePicker: AirDatepicker;

    constructor(target: HTMLElement) {
        super(target);
        this.input = this.element.find('input');
        this.init();
    }

    init(): void {
        const nextArrow: string = Icon.render('chevron-right', 'datepicker__arrow datepicker__arrow--next');
        const prevArrow: string = Icon.render('chevron-left', 'datepicker__arrow datepicker__arrow--prev');

        if (this.input.length) {
            const locale: Partial<AirDatepickerLocale> = window.gotoAndPlay.datepickerLocale ?? {};

            this.datePicker = new AirDatepicker(this.input.get(0) as HTMLInputElement, {
                locale,
                navTitles: {
                    days: 'MMM yyyy',
                    months: 'yyyy',
                    years: 'yyyy - yyyy',
                },
                nextHtml: nextArrow,
                onSelect: () => this.input.trigger('change'),
                position: 'bottom left',
                prevHtml: prevArrow,
            });
        }

    }

}
