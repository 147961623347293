import Choices, { Choices as ChoicesLib } from 'choices.js';

import Component from '../../component/component';
import Icon from '../../icon/icon';

import '../textfield/textfield';

import './select.scss';

export interface ISelectSettings {
    noResultsText: string;
    search: boolean;
    removeItemText: string;
}

export default class Select extends Component {
    static initSelector: string = '.select';

    public settings: ISelectSettings;
    public input: JQuery;
    public choices: Choices;
    public multiple: boolean;

    constructor(target: HTMLElement) {
        super(target);

        this.input = this.element.find('.select__input');

        if (this.input.length) {
            this.settings = $.extend({
                multiselect: false,
                noResultsText: 'No results found',
                removeItemText: 'Remove item',
                search: false,
            }, this.input.data());

            this.multiple = !!this.input.attr('multiple');

            this.init();
        }
    }

    setValue(value: string | string[]): void {
        this.choices.setChoiceByValue(value);
    }

    init(): void {
        this.choices = new Choices(this.input[0] as HTMLInputElement, {
            callbackOnCreateTemplates: (template: ChoicesLib.Types.strToEl): Partial<ChoicesLib.Templates> => ({
                dropdown: (classNames: ChoicesLib.ClassNames): HTMLElement => template(`
                        <div class="${classNames.list} ${classNames.listDropdown}" aria-expanded="false">
                            ${Icon.render('search', '', 'select__search-icon')}
                        </div>
                    `) as HTMLElement,
                item: (classNames: ChoicesLib.ClassNames, data: ChoicesLib.Choice): HTMLElement => template(`
                        <div class="${classNames.item}" data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''} data-deletable>
                            ${data.label}
                            <button type="button" class="${classNames.button}" aria-label="${this.settings.removeItemText}" data-button>${Icon.render('close', '', 'select__close-icon')}</button>
                        </div>
                    `) as HTMLElement,
            }),
            classNames: {
                activeState: 'is-active',
                button: 'select__remove',
                containerInner: 'textfield__input select__inner',
                containerOuter: 'select__container',
                disabledState: 'is-disabled',
                flippedState: 'is-flipped',
                focusState: 'is-focused',
                group: 'select__group',
                groupHeading: 'select__group-heading',
                highlightedState: 'select__item--highlighted',
                input: 'select__choices-input',
                inputCloned: 'select__choices-input--cloned',
                item: 'select__item',
                itemChoice: 'select__item--choice',
                itemDisabled: 'select__item--disabled',
                itemSelectable: 'select__item--selectable',
                list: 'select__list',
                listDropdown: 'select__dropdown',
                listItems: 'select__list--multiple',
                listSingle: 'select__list--single',
                loadingState: 'is-loading',
                noChoices: 'has-no-choices',
                noResults: 'has-no-results',
                openState: 'is-open',
                placeholder: 'select__placeholder',
                selectedState: 'is-selected',
            },
            editItems: false,
            itemSelectText: '',
            noResultsText: this.settings.noResultsText,
            removeItemButton: true,
            removeItems: true,
            renderSelectedChoices: 'always',
            searchChoices: this.settings.search,
            searchEnabled: this.settings.search,
        });

        this.addEventListeners();
    }

    addEventListeners(): void {
        // Multiselect context
        if (this.multiple) {
            // Deselect a selected item when clicking on it.
            this.input[0].addEventListener('choice', (event: CustomEvent) => {
                const values: string[] | string = this.choices.getValue(true);
                const value: string = event.detail.choice.value;

                if (values?.includes(value)) {
                    setTimeout((): Choices => this.choices.removeActiveItemsByValue(value));
                }
            });

            // Close dropdown when clicking on empty space between currently selected items in select list.
            this.element.find('.select__inner > .select__list').on('click', (): void => {
                this.choices.hideDropdown();
            });
        }

        this.input[0].addEventListener('setValue', (event: CustomEvent): void => {
            const values: string | string[] = event.detail;

            if (typeof values !== 'undefined') {
                this.setValue(event.detail);
            }
        });

        this.input[0].addEventListener('hideDropdown', (): void => {
            this.choices.hideDropdown();

            // Clear search input when closing dropdown
            if (this.settings.search) {
                this.choices.clearInput();
            }
        });

        this.input[0].addEventListener('showDropdown', (): void => {
            // Focus search input when opening dropdown if search enabled.
            if (this.settings.search) {
                this.element.find('.select__dropdown .select__choices-input--cloned').trigger('focus');
            }

            this.choices.showDropdown();
        });
    }
}
