import 'what-input';

import './reset.scss';

const appHeight: () => void = () => {
    const doc: HTMLElement = document.documentElement;

    doc.style.setProperty('--app-height', `${document.documentElement.clientHeight}px`);
};

window.addEventListener('resize', appHeight);
appHeight();

const appWidth: () => void = () => {
    const doc: HTMLElement = document.documentElement;

    doc.style.setProperty('--app-width', `${document.documentElement.clientWidth}px`);
};

window.addEventListener('resize', appWidth);
appWidth();
