import './icon.scss';

interface IIconMap {
    [filename: string]: __WebpackModuleApi.RequireContext;
}

const req: __WebpackModuleApi.RequireContext = require.context('./import/svg/', false, /^\.\/.*\.svg$/);

(req.keys()).reduce((glyphs: IIconMap, key: string): IIconMap => {
    const filename: string = key.match(new RegExp(/[^/]+(?=\.svg$)/))[0];

    return {
        ...glyphs,
        [filename]: req(key),
    };
}, {});

export default class Icon {
    static render(name: string, modifier?: string, className?: string): string {
        const use: string = '<use href="' + window.gotoAndPlay.svgPath + '#' + name + '"></use>';
        const classArray: string[] = ['icon'];

        if (modifier) {
            classArray.push(modifier);
        }

        if (className) {
            classArray.push(className);
        }

        return '<svg class="' + classArray.join(' ') + '">' + use + '</svg>';
    }
}
