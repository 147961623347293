import Component from '../../component/component';
import './filefield.scss';

interface IUploader {
    bind: (name?: string, fn?: unknown, scope?: unknown) => void;
    files: File[];
    removeFile: (file: File) => void;
    start: () => void;
}

interface IWrapperSettings {
    container?: string;
}

export default class Filefield extends Component {

    static initSelector: string = '.filefield';

    input: JQuery;
    label: JQuery;
    button: JQuery;
    container: string;
    settings: IWrapperSettings;
    isMultiple: boolean;

    constructor(element: HTMLElement) {
        super(element);
        this.button = this.element.find('.filefield__button');
        this.input = this.element.find('.filefield__input');
        this.label = this.element.find('.filefield__label');
        this.isMultiple = this.element.hasClass('is-multiple');
        this.settings = this.element.find('.filefield__input-wrapper').data('settings');
        this.container = this.settings && typeof this.settings.container !== 'undefined' ? this.settings.container : '';

        if (this.isMultiple) {
            this.input.on('change', this.renderFiles);
        } else {
            this.button.on('click', this.triggerDialog.bind(this));
            this.input.on('change', this.onChange.bind(this));
        }

        this.label.on('dragover dragenter', (): void => {
            this.label.addClass('is-highlighted');
        });
        this.label.on('dragleave dragend drop', (): void => {
            this.label.removeClass('is-highlighted');
        });

        window.setTimeout(() => {
            if (typeof window.gfMultiFileUploader.uploaders !== 'undefined' && this.container) {
                const uploader: IUploader = window.gfMultiFileUploader.uploaders[this.container];

                uploader.bind('FilesAdded', this.renderFiles);
                uploader.bind('FileUploaded', () => {
                    $('.is-loading').removeClass('is-loading');
                });
            }
        }, 0);
    }

    triggerDialog(): void {
        this.label.trigger('click');
    }

    onChange(event: JQuery.ChangeEvent): void {
        const files: File[] = event.target.files;

        this.setFileNames(files);
    }

    renderFiles: () => void = (): void => {
        const uploader: IUploader = window.gfMultiFileUploader.uploaders[this.container];

        this.setFileNames(uploader.files);
    };

    setFileNames(files: File[]): void {
        const fileNames: string[] = [];

        for (const file of files) {
            fileNames.push(file.name);
        }

        if (fileNames.length) {
            this.element.addClass('is-dirty');
        } else {
            this.element.removeClass('is-dirty');
        }

        this.element.find('.filefield__files').text(fileNames.join(', '));
    }

}
