import Component from '../../components/component/component';

import './appear.scss';

interface IAppearSettings {
    hiddenClass?: string
    threshold?: number
}

export const appearSettings: IAppearSettings = {
    hiddenClass: 'is-hidden',
    threshold: .3,
};

export default class Appear extends Component {
    static initSelector: string = '.appear';

    static observer: IntersectionObserver;

    constructor(element: HTMLElement) {
        super(element);

        if (!Appear.observer) {
            const observerSettings: IntersectionObserverInit = {
                threshold: appearSettings.threshold,
            };

            /*
                IntersectionObserver is not supported in KaiOS 2.5, IE 11, Baidu 7.12, Android UC Browser 12.12.
                We decided against using a polyfill due to the low relevancy of these devices/browsers.
            */
            // eslint-disable-next-line compat/compat
            Appear.observer = new IntersectionObserver(Appear.intersectionCallback, observerSettings);
        }

        this.element.addClass(appearSettings.hiddenClass);
        Appear.observer.observe(this.element.get(0));
    }

    static intersectionCallback(entries: IntersectionObserverEntry[]): void {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
                $(entry.target).removeClass(appearSettings.hiddenClass);
                Appear.observer.unobserve(entry.target);
            }
        });
    }

    destroy(): void {
        Appear.observer.unobserve(this.element.get(0));
    }
}
