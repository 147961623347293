import Component from '../../component/component';
import CookieNotification from '../cookie-notification/cookie-notification';
import Notification, { INotification } from '../notification/notification';

import './notification-list.scss';

export default class NotificationList extends Component {
    static initSelector: string = '.notification-list';

    public notifications: IGotoAndPlayNotificationComponent[];

    constructor(element: HTMLElement) {
        super(element);

        this.notifications = window.gotoAndPlay.notifications;

        this.init();
    }

    getNotificationClassByName(name: string): typeof Notification {
        switch (name) {
            case 'CookieNotification':
                return CookieNotification;
            case 'Notification':
            default:
                return Notification;
        }
    }

    init(): void {
        if (this.notifications.length) {
            for (const item of this.notifications) {
                const classInstance: typeof Notification = this.getNotificationClassByName(item.component);

                if (classInstance.shouldShow) {
                    this.add(classInstance, item.data);
                }
            }
        }
    }

    add<T extends typeof Notification>(component: T, notification: INotification): InstanceType<T> {
        const notif: JQuery = component.render(notification, [notification.modifier, 'notification-list__item'].join(' ')).appendTo(this.element);
        const instance: InstanceType<T> = component.create(notif[0]);

        notif.trigger('enhance');
        instance.show();

        return instance;
    }
}
