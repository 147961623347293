import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

import Component from '../../components/component/component';

import './homes-slider.scss';

export default class HomeSlider extends Component {
    static initSelector: string = '.homes-slider';

    sliderElement: JQuery;
    slider: Splide;
    currentSlide: JQuery;
    prevButton: JQuery;
    nextButton: JQuery;
    itemsLength: number;

    constructor(element: HTMLElement) {
        super(element);

        this.sliderElement = this.element.find('.homes-slider__slider');
        this.currentSlide = this.element.find('.js-current-slide');
        this.prevButton = this.element.find('.js-prev');
        this.nextButton = this.element.find('.js-next');
        this.itemsLength = this.element.find('.homes-slider__slide').length;

        if (this.sliderElement.length > 0) {
            this.init();
        }
    }

    init(): void {
        this.slider = new Splide(
            this.sliderElement.get(0),
            {
                autoWidth: true,
                autoHeight: true,
                drag: true,
                focus: 'center',
                perPage: 1,
                arrows: false,
                pagination: false,
            },
        );

        this.slider.mount();

        this.slider.on('move', (destIndex: number): void => {
            const destination: number = destIndex + 1;

            this.currentSlide.text(destination);

            if (destination === this.itemsLength) {
                this.nextButton.prop('disabled', true);
            } else {
                this.nextButton.prop('disabled', false);
            }

            if (destination === 1) {
                this.prevButton.prop('disabled', true);
            } else {
                this.prevButton.prop('disabled', false);
            }
        });

        this.prevButton.on('click', (): void => {
            this.slider.go('<');
        });

        this.nextButton.on('click', (): void => {
            this.slider.go('>');
        });
    }

}
