export default class Component {

    public static initSelector: string;

    public element: JQuery;

    constructor(element: HTMLElement) {
        this.element = $(element);

        $(document).on('destroy', this.handleDestroy);
    }

    public static is(value: unknown): value is typeof Component {
        return Object.getPrototypeOf(value) === Component;
    }

    public static getDataAttributeName(): string {
        const type: string = this.getDataName(this.initSelector);

        return type + 'Class';
    }

    public static create<T extends typeof Component>(element: HTMLElement): InstanceType<T> {
        const $element: JQuery = $(element);
        const dataAttr: string = this.getDataAttributeName();
        const value: InstanceType<T> = $element.data(dataAttr);

        if (!value) {
            const instance: Component = new this(element);

            $element.data(dataAttr, instance);

            return instance as InstanceType<T>;
        }

        return value;
    }

    public static enhance(): void {
        if (this.initSelector) {
            const type: string = Component.getDataName(this.initSelector);

            $(document).on('enhance.' + type, (event: JQuery.TriggeredEvent): void => {
                $(event.target).find(this.initSelector).addBack(this.initSelector).each((index: number, element: HTMLElement): void => {
                    this.create(element);
                });
            });
        } else {
            console.warn(`[Warning] ${this.name} is missing static initSelector.`);
        }
    }

    public static getDataName(initSelector: string): string {
        return initSelector.replace(/\./g, '').replace(/^([A-Z])|[\s-_](\w)/g, (match: string, p1: string, p2: string): string => {
            if (p2) {
                return p2.toUpperCase();
            } else {
                return p1.toLowerCase();
            }
        });
    }

    destroy(): void {
        return;
    }

    handleDestroy: (event: JQuery.TriggeredEvent) => void = (event: JQuery.TriggeredEvent): void => {
        // trigger destroy when event target contains current element
        // and also when element is not in DOM
        if ($.contains(event.target, this.element[0]) || !$.contains(document.body, this.element[0])) {
            this.destroy();

            $(document).off('destroy', this.handleDestroy);
        }
    };
}
